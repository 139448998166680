:root {
	--one-rem-size: 10px;
	--font-family: 'Roboto Light';
	
	/* Sizes */
	/*--size-xs: NOTE: less than 576px*/
	--size-sm: 57.6rem;
	--size-m: 76.8rem;
	--size-lg: 99.2rem;
	--size-xl: 120rem;
	--size-xxl: 160rem;
	
	/* Font */
	/*--font-family: var(--font-family, 'Roboto');*/
	
	--font-size-h1: 3.4rem;
	--font-size-h2: 3.0rem;
	--font-size-h3: 2.6rem;
	--font-size-h4: 2.2rem;
	--font-size-h5: 1.8rem;
	--font-size-p: 1.6rem;
	--font-size-li: 1.6rem;
	--font-size-span: 1.6rem;
	
	/* Colors */
	--color-black: #000000;
	--color-white: #ffffff;
	--color-gray-20: #d3d3d3;
	--color-accent: #6531b4;
	--color-accent-2: #ff009d;
	
	--background-color--primary: var(--color-white);
	--font-color--primary: var(--color-black);
	
	/* Components */
	--page-header-height: 8.4rem;
	--page-content-height: calc(100dvh - var(--page-header-height) - var(--page-footer-height));
	--page-content-max-width: 124rem;
	--page-content-padding: 3.2rem;
	
	--banner-button-height: 5rem;
	--banner-max-height--collapsed: calc(var(--banner-button-height) * 2.5);
	--banner-max-height--expanded: 75dvh;
	
	--page-footer-min-extra-height: 4rem;
	--page-footer-height: calc(var(--banner-max-height--collapsed) + var(--page-footer-min-extra-height));
}

/*@media (prefers-color-scheme: dark) {*/
/*	:root {*/
/*		--background-color--primary: var(--color-black);*/
/*		--font-color--primary: var(--color-white);*/
/*	}*/
/*}*/

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	font-family: var(--font-family);
}

html, body {
	max-width: 100vw;
	overflow-x: hidden;
	font-size: var(--one-rem-size);
	background: var(--background-color--primary);
	color: var(--font-color--primary);
	scroll-behavior: smooth;
}

body > main {
	height: 100dvh;
	overflow: hidden;
}

a {
	color: inherit;
	text-decoration: none;
}

.desktop-links-banner_styles_container__fcLag .desktop-links-banner_styles_expandButton__BiIz4{width:max-content;align-self:center;justify-self:center;transform:rotate(-90deg);padding:.4rem}.desktop-links-banner_styles_container__fcLag .desktop-links-banner_styles_links__QkPsD{list-style-type:none;display:grid;grid-gap:1rem;gap:1rem}.desktop-links-banner_styles_container__fcLag .desktop-links-banner_styles_links__QkPsD .desktop-links-banner_styles_link__tAdih .desktop-links-banner_styles_linkButton__xrTeA{display:flex;justify-content:center;align-items:center;background:var(--color-accent);font-size:var(--font-size-h5);width:100%;height:var(--banner-button-height)}
.mobile-links-banner_styles_container__WvLIT{max-height:var(--banner-max-height--collapsed);transition:max-height 300ms ease}.mobile-links-banner_styles_container__WvLIT.mobile-links-banner_styles_isOpen__axmcR{max-height:var(--banner-max-height--expanded)}.mobile-links-banner_styles_container__WvLIT.mobile-links-banner_styles_isOpen__axmcR .mobile-links-banner_styles_expandButton__zxeIV{transform:rotate(-270deg)}.mobile-links-banner_styles_container__WvLIT .mobile-links-banner_styles_expandButton__zxeIV{display:flex;align-items:center;width:max-content;align-self:center;justify-self:center;padding:.4rem;transform:rotate(-90deg);transition:transform 300ms ease}.mobile-links-banner_styles_container__WvLIT .mobile-links-banner_styles_links__tv60p{display:grid;grid-gap:1rem;gap:1rem;max-height:max-content;overflow:auto;transition:max-height 300ms ease;list-style-type:none}.mobile-links-banner_styles_container__WvLIT .mobile-links-banner_styles_links__tv60p .mobile-links-banner_styles_link__ZfVs5 .mobile-links-banner_styles_linkButton__ZZdz2{display:flex;justify-content:center;align-items:center;background:var(--color-accent);font-size:var(--font-size-h5);width:100%;height:var(--banner-button-height)}
.links-banner_styles_container__awn2x{display:grid;grid-auto-flow:row;align-items:center;padding:1rem}
.aside_styles_container__eBg5E{position:fixed;bottom:0;left:1rem;width:calc(100% - 2rem);display:grid;grid-auto-flow:row;align-items:center;background:var(--background-color--primary);border-radius:1rem 1rem 0 0;box-shadow:0 .8rem 1rem -0.5rem rgba(0,0,0,.14),0 1.6rem 2.4rem .2rem rgba(0,0,0,.1),0 .6rem 3rem .5rem rgba(0,0,0,.08);z-index:1}@media(min-width: 992px){.aside_styles_container__eBg5E{position:-webkit-sticky;position:sticky;top:var(--page-content-padding);width:36rem;height:max-content;border-radius:1rem}}
.footer_styles_container__a4IoG{height:var(--page-footer-height);display:grid;align-items:center;justify-content:center;padding:1rem;margin-bottom:var(--banner-max-height--collapsed);color:var(--font-color--primary);background:var(--background-color--primary)}@media(min-width: 992px){.footer_styles_container__a4IoG{margin-bottom:unset}}
.download-app-button_styles_container__foEu_{display:grid;grid-auto-flow:column;align-items:center;justify-content:center;grid-gap:8px;gap:8px;background:var(--color-accent-2);border-color:var(--color-accent-2);color:var(--color-white);transition:box-shadow 300ms ease-in-out}.download-app-button_styles_container__foEu_:hover{background:var(--color-accent-2) !important;border-color:var(--color-accent-2) !important;color:var(--color-white) !important;box-shadow:2px 0 12px var(--color-accent-2) !important}.download-app-button_styles_container__foEu_ .download-app-button_styles_icon__DdC9a{margin:0 !important;width:max-content}.download-app-button_styles_container__foEu_>:not(.download-app-button_styles_icon__DdC9a){display:none}@media(min-width: 992px){.download-app-button_styles_container__foEu_>:not(.download-app-button_styles_icon__DdC9a){display:unset}}
.language-picker_styles_container__E2gIa.language-picker_styles_select__Jp7P7{height:100% !important}.language-picker_styles_container__E2gIa.language-picker_styles_select__Jp7P7 [class*=-select-selector]{background:unset !important;border:none !important}.language-picker_styles_container__E2gIa.language-picker_styles_select__Jp7P7 [class*=-select-selection-item]{display:inline-flex;align-self:center;justify-self:center}.language-picker_styles_container__E2gIa [class*=-select-item]{padding:0 !important;align-self:center}.language-picker_styles_container__E2gIa [class*=-select-item]:not(:first-child){margin-top:1rem}.language-picker_styles_container__E2gIa img{height:3rem;width:auto;border:1px solid #000}.language-picker_styles_container__E2gIa .language-picker_styles_link__PJT5x{display:flex}
.desktop-menu_styles_container__CHz3o{height:100%;display:grid;grid-auto-flow:column;grid-auto-columns:max-content;align-items:center;grid-gap:3rem;gap:3rem}.desktop-menu_styles_container__CHz3o .desktop-menu_styles_link__jPOHk{padding:.5rem;list-style:none;font-size:1.8rem;color:var(--color-white)}
.mobile-menu_styles_button__uijhd{height:3.2rem;padding:0}.mobile-menu_styles_button__uijhd .mobile-menu_styles_icon__SIJhH svg{height:3.2rem;width:auto}.mobile-menu_styles_button__uijhd .mobile-menu_styles_icon__SIJhH svg path{fill:var(--color-white)}.mobile-menu_styles_drawer__VYKa3{background:var(--color-accent) !important}.mobile-menu_styles_drawer__VYKa3 [class*=-drawer-close] svg path{fill:var(--color-white)}.mobile-menu_styles_drawer__VYKa3 .mobile-menu_styles_links__kxucT{display:grid;grid-auto-flow:row;grid-auto-columns:max-content;align-items:center;grid-gap:3rem;gap:3rem}.mobile-menu_styles_drawer__VYKa3 .mobile-menu_styles_links__kxucT .mobile-menu_styles_link__evHXR{padding:.5rem;list-style:none;font-size:1.8rem;color:var(--color-white)}
.header_styles_container__xBBg2{height:var(--page-header-height);display:grid;grid-template-areas:"logo . lang nav";grid-template-columns:max-content 1fr max-content max-content;align-items:center;grid-gap:2rem;gap:2rem;padding-inline:2rem}.header_styles_container__xBBg2:has(.header_styles_download__HiQnR){grid-template-areas:"logo . download lang nav";grid-template-columns:max-content 1fr max-content max-content max-content}.header_styles_container__xBBg2 .header_styles_logo__jmuOj{grid-area:logo;display:flex;align-self:center;width:12rem;height:auto}.header_styles_container__xBBg2 .header_styles_navigation__f0GME{grid-area:nav;justify-self:flex-end}.header_styles_container__xBBg2 .header_styles_download__HiQnR{grid-area:download;justify-self:flex-end}.header_styles_container__xBBg2 .header_styles_languagePicker__xCCxC{grid-area:lang;justify-self:center}@media(min-width: 992px){.header_styles_container__xBBg2{grid-template-areas:"logo nav lang";grid-template-columns:max-content 1fr max-content;gap:4rem}.header_styles_container__xBBg2:has(.header_styles_download__HiQnR){grid-template-areas:"logo nav download lang";grid-template-columns:max-content 1fr max-content max-content}.header_styles_container__xBBg2 .header_styles_navigation__f0GME{justify-self:flex-start}}
.slug-page-layout_styles_container__Aaf_f{position:relative;height:100dvh;display:grid;grid-auto-flow:row}.slug-page-layout_styles_container__Aaf_f .slug-page-layout_styles_header__SAMdT{background:var(--color-accent);position:relative}.slug-page-layout_styles_container__Aaf_f .slug-page-layout_styles_headerContent__LSjPk{max-width:var(--page-content-max-width);margin-inline:auto;padding-inline:var(--page-content-padding)}.slug-page-layout_styles_container__Aaf_f .slug-page-layout_styles_body__0KzHF{overflow:auto;position:relative}.slug-page-layout_styles_container__Aaf_f .slug-page-layout_styles_bodyContent__P1cCL{display:grid;grid-template-columns:1fr max-content;max-width:var(--page-content-max-width);margin-inline:auto;padding:var(--page-content-padding)}.slug-page-layout_styles_container__Aaf_f .slug-page-layout_styles_body__0KzHF .slug-page-layout_styles_footerContent__wPtoy{max-width:var(--page-content-max-width);margin-inline:auto;padding-inline:var(--page-content-padding)}@media(min-width: 992px){.slug-page-layout_styles_container__Aaf_f .slug-page-layout_styles_bodyContent__P1cCL{gap:var(--page-content-padding)}}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/7f9c2bb12d05b4c1-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/e22508e41752d816-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/d67e8433214df714-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/ed37791012a28541-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/31a961c285846cb0-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/9d97415e38cab482-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/02edef4d0edfad6d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/ac0efabfe978b0ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/7d1684f14ddac155-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/82233a533941ac93-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/0881a2b922b3331e-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/994bf73bb06543dc-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/279b47070a5d5877-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/045832894acda0e9-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/483de911b1a0d258-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0e5e1c6a8db9e432-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/5693677ef07d9b51-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/28aa5118b38b86e4-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/8720059dfa14a1fe-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/f1df6186c8d69644-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/120a5a1920781bd0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/80b1a0e600ca6d83-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7c16c8204ab29534-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/f756da832d8c34d4-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/98a28a5430a3cf7f-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/df2942b6de9d14b5-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7a7012758df5a81e-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/6ebb97b5c9fa4e03-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/8c3de32e92410013-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/e1d0f983e2a3e4f5-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/5721dfda5b43cc5f-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/d602976d04712c39-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/879d1750a4bfabb3-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/550cf1913d99b09c-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_6ea8a3';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/c714540e49ad5111-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Roboto_Fallback_6ea8a3';src: local("Arial");ascent-override: 92.67%;descent-override: 24.39%;line-gap-override: 0.00%;size-adjust: 100.11%
}.__className_6ea8a3 {font-family: '__Roboto_6ea8a3', '__Roboto_Fallback_6ea8a3';font-style: normal
}.__variable_6ea8a3 {--font-family: '__Roboto_6ea8a3', '__Roboto_Fallback_6ea8a3'
}


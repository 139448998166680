.container {
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	justify-content: center;
	gap: 8px;
	background: var(--color-accent-2);
	border-color: var(--color-accent-2);
	color: var(--color-white);
	transition: box-shadow 300ms ease-in-out;
	
	&:hover {
		background: var(--color-accent-2) !important;
		border-color: var(--color-accent-2) !important;
		color: var(--color-white) !important;
		box-shadow: 2px 0 12px var(--color-accent-2) !important;
	}
	
	.icon {
		margin: 0 !important;
		width: max-content;
	}
	
	& > :not(.icon) {
		display: none;
	}
}


@media (width >= 992px) {
	.container {
		& > :not(.icon) {
			display: unset;
		}
	}
}
